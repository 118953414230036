import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Link from '../../components/link'
import List from '../../components/list'
import ListItem from '../../components/list-item'
import SEO from '../../components/seo'


export const query = graphql`
  query SeasonsSitemapPageQuery {
    ...SiteInfo
    results: postgres {
      seasons: allSeasonsList(orderBy: SORT_ASC) {
        ...Season
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { seasons } = data.results
  const { entity } = meta

  const page = `${entity.singular} Seasons Sitemap`
  const title = `${page} - Sitemaps`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Sitemap',
      slug: links.sitemap.home,
    },
    {
      name: 'Seasons',
      slug: links.sitemap.seasons,
    }
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    slug: links.sitemap.seasons,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <List>
        <ListItem>
          <Link href={links.seasons}>All Seasons</Link>
        </ListItem>
        {seasons.map(season => (
          <ListItem key={season.id}>
            <Link href={season.slug}>{season.name}</Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
